'use strict';
App.main = (function() {
	function _init() {
		// set moment.js language
		moment.lang('nl');
		$.publish('APP/bootstrap');
		// window scrolled
		// $(window).on('scroll', debounce(_scroll, 10, true));
		$(window).on('scroll', _scroll);
		// window resize
		// removed matchMedia! Android doesn't support orientationchange and uses "resize" instead.
		// matchmedia only triggers when the breakpoints change, which isn't the case when rotating a mobile phone
		$(window).on('resize', debounce(_resize, 100));
		// on orientation change fire the resize event
		$(window).on('orientationchange', _resize);
		// popstate
		$(window).on('popstate', _popstate);
		// listen to post messages - used for ad/iframe/... communication
		$(window).on('message', _dispatchMessage);
		/* example postMessage

			var data = {
				'action': '/advertising/increase',
				'values': {
					'height': '200px'
				}
			};
			window.postMessage(JSON.stringify(data), '*');
		*/
	}

	function _load() {
		$.publish('APP/load');
	}

	function _scroll() {
		$.publish('APP/scroll');
	}

	function _resize() {
		$.publish('APP/resize');
	}

	function _dispatchMessage(args) {
		var data;

		try { // google dfp also uses postMessage, which will throw an error on JSON.parse();
			data = JSON.parse(args.data);
			$.publish(data.action, data.values);
		} catch (e) {
			// kiss my ass
		}
	}

	function _popstate(e) {
		$.publish('APP/popstate', e);
	}

	return {
		'init': _init,
		'load': _load
	};
}());

// dom loaded with IE fix:
if (document.attachEvent) {
	document.attachEvent('onreadystatechange', function() {
		if (document.readyState === 'complete') {
			App.main.init();
		}
	});
} else {
	$(document).on('DOMContentLoaded', App.main.init);
}
// window onload
$(window).on('load', App.main.load);

// window onerror
window.onerror = function() {
	return $.publish('APP/error', arguments);
};
