'use strict';
(function slideshow() {
	var $slideshow,
		$container,
		_cycle = {};

	$.subscribe('APP/bootstrap', _init);

	function _init() {
		var hammertime;

		// check if we have a slideshow
		$slideshow = $('.fjs-slideshow');
		if ($slideshow.length > 0) {
			// get the container
			$container = $('.slideshow__wrapper', $slideshow);

			// change display to inline block for all slides
			$slideshow.addClass('js-ready');
			if ($('.slideshow__slide', $container).length > 1) {
				_cycle = new Cycle({
					'cycle': $container,
					'container': $container,
					'slides': $('.slideshow__slide', $container),
					'initialSlide': 0,
					'navigation': $('.fjs-slideshow-nav', $slideshow),
					'next': '.slideshow__nav--next',
					'previous': '.slideshow__nav--prev',
					'bullets': $('.slideshow__bullet', $slideshow),
					'loop': true,
					'animation': 'css',
					'autoplay': false,
					'timer': 5000,
					'onSlideChange': _changeSlide
				});
				$slideshow.data('cycle', _cycle);
			}

			$('a.fjs-slideshow-next').on('click', function(e) {
				_cycle.showNext();
				e.preventDefault();
			});

			// set up keybinding
			_keyBinding();

			// if touch based go nuts!
			if ($('.touchevents').length > 0) {
				hammertime = new Hammer($('.slideshow__wrapper', $slideshow)[0], {
					'recognizers': [
						[Hammer.Swipe, {
							'direction': Hammer.DIRECTION_HORIZONTAL
						}]
					]
				});
				hammertime.on('swipe', function(e) {
					if (e && e.direction) {
						switch (e.direction) {
							case 2: // right;
								_cycle.showNext();
								break;
							case 4: // left;
								_cycle.showPrevious();
								break;
						}
					}
				});
			}
		}
	}

	// function _goToSlide(e) {
	// 	$slideshow.each(function() {
	// 		$(this).data('cycle').show(e.state.slideNumber, 'left');
	// 	});
	// }

	function _changeSlide(args) {
		// show the slide
		$container.css({
			'left': 0 - args.slideNumber * 100 + '%'
		});
		// check if we have page shizzle
		if ($container.parents('.fjs-slideshow').hasClass('fjs-page-nav')) {
			$('.fjs-page-slide').removeClass('slideshow__page--active');
			$('.fjs-page-slide[data-page-nr="' + args.slideNumber + '"]').addClass('slideshow__page--active');
		}
		// remove active stuff from bullets
		_changeBullets(args);
	}

	function _changeBullets(args) {
		args.bullets.removeClass('active');
		args.bullets[args.slideNumber].className += ' active';
	}

	function _keyBinding() {
		$(window).on('keydown.photoset', function(e) {
			switch (e.keyCode) {
				case 27: // escape key
					window.event.returnValue = false;
					document.location = $('.fjs-escape').attr('href');
					break;
				case 37: // left;
					$('.slideshow__nav--prev', $slideshow).fire('click');
					break;
				case 39: // right
					$('.slideshow__nav--next', $slideshow).fire('click');
			}
		});
	}
}());
